.footer {
  background-color: #09143c;
  color: white;
  padding: 1rem;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 82px;
}

.footer-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.footer-link {
  font-size: 0.9rem;
  color: white;
  text-decoration: none;
  margin: 0 0.5rem;
}

.footer-divider {
  font-size: 0.9rem;
  margin: 0 0.5rem;
}
