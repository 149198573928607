.cart-page {
    margin-top: 64px;
  }
  
  .cart-page h1 {
    padding: 15px !important;
    font-family: "roboto", sans-serif;
    font-weight: normal;
    background-color: rgba(0, 0, 255, 0.072) !important;
  }
  .cart-page h1 > p {
    font-size: 20px;
    margin-top: 10px;
  }
  
  .cart-page .card {
    padding: 5px !important;
    height: 200px !important;
    margin-bottom: 5px !important;
  }
  .cart-page .cart-remove-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cart-page .cart-summary {
    text-align: center;
    /* margin-top: -140px !important; */
  }
  .cart-page .paystack-button {
    text-align: center;
    background-color: #1665da;
    border-radius: 20;
    color: #fff;
  
    /* margin-top: -140px !important; */
  }