.image-container {
    width: 50%;
    float: left;
  }
  
  .form-container {
    width: 50%;
    float: left;
    padding: 50px;
  }
  
  @media (max-width: 768px) {
    .image-container {
      width: 100%;
      float: none;
    }
    .form-container {
      width: 100%;
      float: none;
    }
  }
  
  .image {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .title {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .btn {
    display: block;
    width: 100%;
    margin-top: 20px;
  }
  
  @media (max-width: 576px) {
    .form-control {
      font-size: 14px;
    }
  }
  