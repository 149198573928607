.product-carousel-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
  }
  
  .product-carousel-image {
    height: 400px;
    object-fit: contain;
    cursor: pointer;
  }
  
  .carousel .slide {
    display: flex;
    justify-content: center;
  }
  
  .carousel .slide img {
    max-width: 100%;
    max-height: 100%;
  }
  
  .carousel .control-arrow {
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #333;
    background: rgba(255, 255, 255, 0.8);
    border: none;
    outline: none;
    transition: all 0.2s ease-in-out;
  }
  
  .carousel .control-arrow:hover {
    background: #fff;
    color: #333;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  }
  
  .carousel .control-arrow:focus {
    outline: none;
  }
  
  .carousel .control-prev.control-arrow:before {
    content: "<";
  }
  
  .carousel .control-next.control-arrow:before {
    content: ">";
  }
  