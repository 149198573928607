@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Poppins:wght@300;400&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* font-family: 'Poppins', sans-serif; */
/* font-family: 'Playfair Display', serif; */

/* //navbar css */
.navbar {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  line-height: 26px;
  box-shadow: 0 8px 6px -6px gray;
  --webkit-box-shadow: 0 8px 6px -6px gray;
  border-bottom: solid black !important;
  background-color: white;
}

.nav-link {
  font-weight: 300 !important;
  color: black !important;
}

.active {
  border-bottom: 2px solid #1665da;
}

.navbar-brand {
  font-weight: 700;
  font-family: "roboto", sans-serif;
  letter-spacing: 3px;
  color: #1665da;
}
/* =============================
==========footer============= */

.footer {
  color: white;
  padding: 25px;
  background-color: #1665da;
}

.footer a {
  text-decoration: none;
  color: white;
  padding: 10px;
}

.footer a:hover {
  color: white;
  border-bottom: 1px solid white;
}
/* =================================
======== page not found css ======= */
.pnf {
  display: flex;
  min-height: 65vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pnf-title {
  font-size: 100px;
  font-weight: 700;
  color: #1665da;
}

.pnf-heading {
  font-weight: normal;
}

.pnf-btn {
  color: white;
  border: 1px solid white;
  text-decoration: none;
  padding: 10px;
  margin-top: 10px;
}
.pnf-btn:hover {
  background-color: white;
  color: #1665da;
}
/* ========================================= */
/* =========contact us ========= */
.contactus {
  margin: 0;
  padding: 0;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.contactus form input {
  border: none;
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 10px;
}

.contactus form textarea {
  border: none;
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 10px;
}

.contactus form button {
  border: none;
  border-radius: 20px;
  padding: 10px;
  background-color: #1665da;
  color: white;
}

.contactus form button:hover {
  background-color: white;
  color: #1665da;
}
