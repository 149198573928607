.banner-img {
    margin-top: 60px;
    
  }
  
  .home-page h1 {
    color: #09143c;
   
  }
  
  .home-page {
    display: flex;
    flex-wrap: wrap;
  }
  .home-page .card {
    background-color: #e7e9ec;
    width: 100%;
  }
  .home-page .card-name-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .home-page .card-price {
    color: #09143c;
    font-weight: bold;
  }
  .home-page button {
    border-radius: 100;
    width: 100%;
   
    font-size: 14px;
    font-weight: bold;
  }
  
  .home-page .card-text {
    color: rgb(90, 89, 89);
  }
  
  .home-page .card img:hover {
    transform: scale(0.9);
  }
  
  .home-page .card-img-top {
    height: 200px !important;
  }
  .home-page  .card-title {
    margin-bottom: 0.5rem;
    color: #1665da;
  }
  /* ==================
  filters
  ============== */
  .filters h4 {
    margin-top: 100px;
    
    color: #09143c !important;
    border-bottom: 1px solid rgba(128, 128, 128, 0.337);
  }
  
  .ant-checkbox-wrapper:first-of-type {
    margin-left: 8px;
  }
  
  .ant-checkbox-wrapper {
    margin: 2px;
  }
  .ant-radio-wrapper {
    margin-bottom: 5px !important;
    margin-left: 10px;
  }
  
  .filters button {
    background-color: #1665da;
    width: 100%;
    border: none;
    border-radius: 0;
    margin-top: 20px;
  }
  
  .loadmore {
    color: green;
    font-weight: bold;
    font-size: 20px !important;
  }

  
  .card-img-top {
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  .card-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1rem;
  }
  
  .card-title {
    margin-bottom: 0.5rem;
  }
  
  .card-price {
    margin-top: auto;
  }
  
  .btn {
    margin-top: auto;
  }