.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: slideInLeft 1s ease-in-out;
}

.form-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: slideInRight 1s ease-in-out;
}

.form {
  max-width: 400px;
  width: 100%;

  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-in-out;
}

.title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

input[type="email"],
input[type="password"] {
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
  font-size: 16px;
}

button[type="submit"],
button[type="button"] {
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

button[type="submit"]:hover,
button[type="button"]:hover {
  background-color: #0056b3;
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
