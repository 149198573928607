.category {

  margin-bottom: 80px;
  min-height: calc(100vh - 100px)
}

.category h4 {
  margin-bottom: 20px;
  font-weight: bold;
 
}

.category h6 {
  margin-bottom: 30px;
  font-weight: normal;
}

.card {
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: box-shadow 0.3s ease-in-out;
}
.category {
  margin-top: 50px;
  margin-bottom: 80px;
  min-height: calc(100vh - 100px); /* adjust the value of 100px based on the height of your footer */
}

.category h4 {
  margin-bottom: 20px;
  font-weight: bold;
  text-transform: uppercase;
}

.category h6 {
  margin-bottom: 30px;
  font-weight: normal;
}

.card {
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: box-shadow 0.3s ease-in-out;
}

.card:hover {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
}

.card-img-top {
  height: 100%;
  object-fit: cover;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.card-title {
  margin-bottom: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}

.card-text {
  margin-bottom: 20px;
  font-size: 0.9rem;
  color: #777;
}

.card-price {
  font-size: 1.2rem;
  font-weight: bold;
  color: #008080;
}

.btn {
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: uppercase;
}

.btn-primary {
  background-color: #1665da;
  border-color: #008080;
}

.btn-primary:hover {
  background-color: #006666;
  border-color: #006666;
}

.btn-dark {
  background-color: #09143c;
  border-color: #333;
}

.btn-dark:hover {
  background-color: #222;
  border-color: #222;
}

.card:hover {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
}

.card-img-top {
  height: 100%;
  object-fit: cover;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.card-title {
  margin-bottom: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}

.card-text {
  margin-bottom: 20px;
  font-size: 0.9rem;
  color: #777;
}

.card-price {
  font-size: 1.2rem;
  font-weight: bold;
  color: #008080;
}

.btn {
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: uppercase;
}

.btn-primary {
  background-color: #1665da;
  border-color: #3fa3e1;
}

.btn-primary:hover {
  background-color: #0b93c4;
  border-color: #2291d1;
}

.btn-dark {
  background-color: #09143c;
  border-color: #333;
}

.btn-dark:hover {
  background-color: #535050;
  border-color: #3e3434;
}
body {
  position: relative;
  min-height: 100vh;
}

main {
  padding-bottom: 84px; /* height of the footer */
  min-height: calc(100vh - (60px + 84px)); /* height of header + footer */
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 84px;
  background-color: #f5f5f5;
}
